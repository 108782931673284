import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import UploadPayoutsFileComponent from "@components/admin/transactions/PayoutsComponents/UploadPayoutsFileComponent";
import { Box, Dialog, DialogContent } from "@material-ui/core";
import { CsvRowProcessingResult } from "@reshopper/admin-client";
import { useEffect, useState } from "react";

export default withAdminMenuLayout(() => {
	const [response, setResponse] = useState<CsvRowProcessingResult>();
	useEffect(() => console.log(response), [response]);

	const padding = 2;

	return <>
		<h2>Payouts</h2>

		<Dialog open={!!response} onClose={() => setResponse(undefined)}>
			{response && <DialogContent>
				<div>
					<b>Succeeded:</b> {response.successCount}
				</div>
				<div>
					<b>Skipped:</b> {response.skipCount}
				</div>
				<div>
					<p>
						<b>Failed:</b> {response.failureMessages.length}
					</p>
					{response.failureMessages.map(
						(message, index) => <p key={`message-${index}`}>
							{message}
						</p>)}
				</div>
			</DialogContent>}
		</Dialog>

		<UploadPayoutsFileComponent
			call={async file => setResponse(await adminClient().adminPayoutsUploadPayoutsReportPost({
				body: {
					fileContents: file
				}
			}))}
			title="Upload payouts reports" />

		<Box padding={padding} />

		<UploadPayoutsFileComponent
			call={async file => setResponse(await adminClient().adminPayoutsNotifyUserOfErroredPayoutsPost({
				body: {
					fileContents: file
				}
			}))}
			title="Notify users of errored payouts" />

		<Box padding={padding} />

		<UploadPayoutsFileComponent
			call={async file => setResponse(await adminClient().adminPayoutsNotifyUserOfPayoutReturnedPost({
				body: {
					fileContents: file
				}
			}))}
			title="Notify users of returned payouts" />

		<Box padding={padding} />
	</>
});