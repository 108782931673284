import ProgressButton from "@components/admin/ProgressButton";
import FileUpload from "@components/FileUpload";
import { useState } from "react";

export default function UploadPayoutsFileComponent(props: { call: (fileContents: string) => Promise<any>, title: string }) {

	const [files, setFiles] = useState<File[]>([]);

	const onSubmit = async (increment: () => void) => {
		for (let file of files) {
			const buffer = await file.arrayBuffer();
			const array = new Uint8Array(buffer);
			const decoder = new TextDecoder('windows-1252');
			const decodedValue = decoder.decode(array);
			await props.call(decodedValue);
			increment();
		}

		setFiles([]);
	};

	return <>
		<h3>{props.title}</h3>
		<FileUpload onChanged={setFiles} />
		<ProgressButton
			disabled={files.length === 0}
			progressVariant="determinate"
			total={files.length}
			onClick={onSubmit}
			variant="contained"
			color="primary"
			style={{
				alignSelf: 'flex-start',
				marginTop: 16
			}}
		>
			Submit reports
		</ProgressButton>
	</>
}

